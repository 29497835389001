<template>
  <div class="app">
    <h1>Are you a robot?</h1>
    <form action="/recaptcha" method="POST" @submit.prevent="validateRecaptcha">
      <div ref="recaptchaElement" class="g-recaptcha" data-sitekey="6Le5JygpAAAAADe3eIa9iVV3drLJ9uRPKS2nOSus"></div>
      <br/>
      <input type="submit" value="Submit" :disabled="disabled" class="submit-button">
    </form>
    <div v-if="result" class="result-box">
      {{ resultMessage }}
    </div>
  </div>
</template>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.app {
  margin: 1em;
}

</style>

<script>

export default {
  data () {
    return {
      result: null,
      disabled: false,
    }
  },
  computed: {
    resultMessage () {
      return this.result.error_message ?? this.result.success_message;
    }
  },
  methods: {
    formDataToPairs(formData) {
      let pairs = {};
      for (let [key, value] of formData.entries()) {
        pairs[key] = value;
      }
      return pairs;
    },
    validateRecaptcha (input) {
      this.disabled = true;
      const formData = this.formDataToPairs(new FormData(input.target));
      
      formData.fbUserAgent = !!navigator.userAgent.match(/FBAN|FBAV/i);
      formData.fbGlobalItem = (typeof FB_IAB !== 'undefined');
      formData.fbClassPresent = !!document.documentElement.classList.contains('in-app-browser');
      formData.fullUserAgent = navigator.userAgent;
      formData.grecaptchaObject = window.grecaptcha.toString();

      fetch('https://robot.codetraindev.co.uk/recaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      })
          .then(response => response.json())
          .then(message => {
            this.result = message;
          })
          .finally(() => {
            this.disabled = false;
          })
    },
  },
  mounted () {
    window.grecaptcha.render(this.$refs.recaptchaElement);
  },
}

</script>

<style>

.result-box {
  margin-top: 1em;
  padding: 1em 2em;
  background-color: #fff3bc;
  color: #65550d;
}

.submit-button {
  background-color: #fff3bc;
  border: none;
  color: #000000;
  padding: 10px 20px;
  border-radius: 5px;
}

.submit-button:hover {
  background-color: #ffcc00;
}

.submit-button:disabled {
  cursor: not-allowed;
  background-color: aliceblue;
  color: #999999;
}

</style>
